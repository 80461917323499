<template>
    <div class="coupon-page">
        <div class="title-bar">
            <div class="title">优惠券</div>
            <div class="add-btn" @click="showDialog">添加优惠码</div>
        </div>
        <div v-if="couponList.length>0" class="coupon-box">
            <div class="coupon-item" v-for="item in couponList" :key="item.id">
                <div class="detail">
                    <div class="title">{{ item.title }}
                    </div>
                    <div class="expired">{{ item.expired_at }}</div>
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        title="使用规则"
                        :content="item.description">
                        <div class="rules" slot="reference">使用规则</div>
                    </el-popover>
                    <div class="circle circleTop"></div>
                    <div class="circle circleBottom"></div>
                </div>
                <div class="money"><span style="font-size: 16px">￥</span>{{ item.money }}</div>
            </div>
        </div>
        <NoContent v-else :data="noContentData"></NoContent>
        <el-dialog
            title="添加优惠券"
            :visible.sync="addcoupondialog"
            width="30%"
            center=""
        >
            <input type="text" class="cdKey" placeholder="请填写优惠码" v-model="cdkey">
            <span slot="footer" class="dialog-footer">
        <div class="addButton" @click="addCoupon">
          确定
        </div>
      </span>
        </el-dialog>
    </div>

</template>

<script>
import NoContent from '@/components/NoContent/NoContent'
import { couponList, getCoupon } from '@/api/user/user'
import { formatMoment } from '@/utils/utils'

export default {
    components: {
        NoContent
    },
    name: 'Coupon',
    data () {
        return {
            addcoupondialog: false,
            couponList: [],
            noContentData: {
                pic: 'coupon_pic',
                tip: '包里没有优惠券呢'
            },
            cdkey: ''
        }
    },
    mounted () {
        this.getCouponList()
    },
    methods: {
        getCouponList () {
            couponList((res) => {
                res.forEach(coupon => {
                    coupon.expired_at = formatMoment(coupon.expired_at)
                })
                this.couponList = res
            })
        },
        addCoupon () {
            let params = {
                code: this.cdkey
            }
            getCoupon(params, () => {
                this.addcoupondialog = false
                this.cdkey = ''
                this.getCouponList()
            })
        },
        showDialog () {
            this.addcoupondialog = true
        }
    }
}
</script>

<style lang="less" scoped>
/*@import "../../../assets/style/lib-base";*/

.coupon-page {
    padding: 30px;

    .cdKey {
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        border-bottom: 1px solid #e6e6e6;
        font-size: @fontSizeSubTitle;
    }

    & > .title-bar {
        box-sizing: border-box;
        height: 40px;
        width: 100%;
        border-bottom: 1px solid #E6E6E6;
        padding: 0 15px 20px;
        line-height: 40px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        & > .title {
            color: #333333;
        }

        & > .add-btn {
            color: @papaGreen;
            cursor: pointer;
        }
    }

    & > .coupon-box {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        padding: 1.9333%;
        box-sizing: border-box;
        justify-content: space-between;

        & > .coupon-item {
            display: flex;
            float: left;
            min-width: 400px;
            margin: 0 10px 20px;

            & > .detail {
                width: 65%;
                border: 1px solid #e6e6e6;
                border-right: 1px dashed #e6e6e6;
                position: relative;
                text-align: left;
                padding: 16px 20px;

                & > .title {
                    font-size: @fontSizeSpTitle;
                    color: #333;
                    font-weight: bold;
                }

                & > .expired {
                    font-size: @fontSizeCon;
                    color: #999;
                    margin-top: 5px;
                }

                .rules {
                    font-size: @fontSizeCon;
                    color: #999;
                    position: absolute;
                    bottom: 16px;
                    cursor: pointer;
                }


                & > .circle {
                    width: 12px;
                    height: 6px;
                    border: 1px solid #e6e6e6;
                    background: #fff;
                }

                & > .circleTop {
                    position: absolute;
                    top: -1px;
                    right: -7px;
                    border-top: none;
                    border-radius: 0 0 12px 12px;
                }

                & > .circleBottom {
                    position: absolute;
                    bottom: -1px;
                    right: -7px;
                    border-bottom: none;
                    border-radius: 12px 12px 0 0;
                }
            }

            & > .money {
                width: 35%;
                border: 1px solid #e6e6e6;
                border-left: none;
                color: #FF5864;
                font-size: 28px;
                line-height: 110px;
                font-weight: bold;
            }

        }
    }

    .addButton {
        margin: 0 auto;
        .btnGreen(160px, 40px, 3px)
    }
}
</style>
